
@media (orientation: portrait) {
  .header--welcomePage {
    height: 341px;
    background: url('../../assets/images/welcomePage_header_vertical.png');
    background-position: bottom;
    background-repeat: no-repeat;
  }
}

@media (orientation: landscape) {
  .header--welcomePage {
    height: 325px;
    background: url('../../assets/images/start_page_header_vertical.png');
    background-position: bottom;
  }
}