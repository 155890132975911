
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  flex-shrink: 0;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 10px 22px 0px rgba(0, 0, 0, 0.04), 0px 39px 39px 0px rgba(0, 0, 0, 0.03), 0px 89px 53px 0px rgba(0, 0, 0, 0.02), 0px 158px 63px 0px rgba(0, 0, 0, 0.01), 0px 247px 69px 0px rgba(0, 0, 0, 0.00);
  border-radius: 20px;
  background-color: #fff;
  padding: 0 24px;
}