h3 {
  font-family: 'Moscow Sans';
font-weight: 800;
  font-size: 16px;
  font-style: normal;
  line-height: 18px;
}

.gameHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 42px;
}

.sensorSwitcher {
  color: var(--neutral-tertiary, #72757B);
  text-align: right;
  
  font-size: 16px;
  font-style: normal;
  line-height: 17.5px; /* 109.375% */
  display: flex;
  align-items: center;
}

.sensorSwitcher div {
  float: left;
  margin-right: 16px;
}

.controls {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.controls h3 {
  color: var(--neutral-secondary, #515358);
  
  font-size: 19px;
  font-style: normal;
  
  line-height: 18px; /* 94.737% */
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controls h3 img {
  margin-right: 8px;
}

.controls .joystic {
  margin-top: 21px;
  margin-bottom: 32px;
}

.gamePage .left .cursor {
  position: relative;
  top: 10px;
  height: 29px;
  margin: 0 5px;
}

.carIcon {
  width: 30px;
  position: relative;
  top: 5px;
  margin: 0 5px;
}

.carIcon path {
  animation: changeFillColor 10s infinite;
}

.gamePage .left .card h4 {
  color: #EF6363;
  font-family: 'Moscow Sans';
font-weight: 800;
  font-size: 19px;
  font-style: normal;
  line-height: 18px; /* 94.737% */
  margin-top: 51px;
}

.gamePage .left .card h2 {
  margin-top: 0;
  color: var(--neutral-main, #313234);
  /* Header/Sm Bold */
  font-family: 'Moscow Sans';
font-weight: 800;
  font-size: 24px;
  font-style: normal;
  line-height: 32px; /* 133.333% */
}
.gamePage .left .card p img {
  margin-right: 16px;
}

.gamePage .title {
  margin-top: 24px;
}

/* Задаем анимацию смены цветов */
@keyframes changeColor {
  0% { color: #E531BD; }
  16.67% { color: #FF7A00; }
  33.33% { color: #167BF1; }
  50% { color: #4CBEFF; }
  66.67% { color: #9ED600; }
  83.33% { color: #FFC01E; }
  100% { color: #E94343; }
}
@keyframes changeFillColor {
  0% { fill: #E531BD; }
  16.67% { fill: #FF7A00; }
  33.33% { fill: #167BF1; }
  50% { fill: #4CBEFF; }
  66.67% { fill: #9ED600; }
  83.33% { fill: #FFC01E; }
  100% { fill: #E94343; }
}

/* Применяем анимацию к тексту */
.changingText {
  animation: changeColor 10s infinite;
}

.card__beforeStart {
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
}
.card__beforeStart .card__beforeStart__text {
  color: #313234;
  font-size: 19px;
  line-height: 18px; /* 94.737% */
}

.card__beforeStart__timer {
  color: #313234;
  font-size: 24px;
  line-height: 18px; /* 75% */
}

.gamePage .left .card__changeMode {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
}

.gamePage .body .left .card__beforeStart--started .card__beforeStart__text {
  color: #F16262;
  font-size: 19px;
  line-height: 18px; /* 94.737% */
}

.gamePage .blackText {
  color: #000 !important;
}

.gamePage .card__changeMode .card__changeMode__text {
  color: #9DA1AF;
  font-size: 19px;
  line-height: 17.5px; /* 92.105% */
}

.gamePage .sensor_joy {
  width: 244px;
}
.gamePage .sensor_joy img {
  width: 100%;
}
.gamePage .sensor_joy .outer-circle img {
  width: 122px;
}

@media (orientation: portrait) {
  .gamePage .body {
    padding: 24px 24px;
    margin: 0;
    flex-direction: column;
  }

  .gamePage .card {
    margin-top: 6px;
    padding-left: 29px;
    padding-right: 29px;
  }

  .gamePage .card__beforeStart {
    padding-top: 26px;
    padding-bottom: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .gamePage .card__description {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .gamePage .card__hint {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 19px;
  }

  .gamePage .card__hint img {
    margin-right: 16px;
  }

  .gamePage .card__changeMode {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .gamePage .card__gameControls {
    padding: 31px 29px;
  }

  .gamePage div.card--red {
    padding: 10px 29px;
  }

  .gamePage .card__gameControls h3 {
    text-align: center;
  }

  .gamePage .card__gameControls {
    text-align: center;
    font-family: Moscow Sans;
    font-size: 19px;
    line-height: 18px; /* 94.737% */
  }

  .gamePage .left .cursor {
    margin: 0;
  }

  .gamePage .left, .gamePage .right {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
  }

  .gamePage .controls {
    flex-direction: column;
  }

  .gamePage .controls .about {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .gamePage .controls .about img {
    width: 22px;
    margin-right: 15px;
  }

  .gamePage .card--red .about p {
    text-align: center;
  }

  .gamePage .right h3 {
    margin-top: 0;
  }
  .gamePage .about p {
    margin: 0;
  }

  .gamePage .joystic_container, .gamePage .sensor_joy {
    margin-left: 0;
    margin-top: 24px;
    width: 199px;
  }
  .gamePage .sensor_joy .outer-circle {
    position: absolute;
    left: 28px;
    top: 27px;
    width: 139px;
    height: 139px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: none;
  }

  .gamePage .sensor_joy {
    margin-top: 15px;
    margin-bottom: 24px;
  }  
  .gamePage .joystic_container img {
    max-width: 100%;
  }  

  .gamePage .sensor_joy .outer-circle img {
    width: 99px;
  }
}

@media (orientation: landscape) {
  .gamePage .body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    grid-template-rows: 1fr 1fr;
  }
  .gamePage .left {
    margin-right: 0;
    width: 100%;
  }
  .gamePage .left .card {
    height: auto;
  }
  .gamePage .right {
    width: 100%;
    margin-left: 0;
  }
  .gamePage .card__description {
    padding: 49px 29px;
  }

  .gamePage .card__beforeStart {
    padding: 24px 29px;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .gamePage .gameHint__top {
    display: flex;
    flex-wrap: nowrap;
  }

  .gamePage .card {
    box-sizing: border-box;
  }

  .gamePage .card__hint {
    width: 26%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 20px 22px;
  }
  .gamePage .card__hint img:nth-child(1) {
    width: 32px;
  }
  .gamePage .card__hint img:nth-child(2) {
    width: 26px;
  }
  .gamePage .card__changeMode {
    width: calc(74% - 5px);
    margin-left: 5px;
  }

  .gamePage .card__changeMode {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 15px 22px;
  }

  .gamePage .card__gameControls {
    padding: 20px 22px;
    margin-top: 7px;
  }
  .gamePage .controls {
    margin-top: 0;
  }
  .gamePage .controls .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
  }
  .gamePage .controls .about img {
    width: 42px;
  }
  .gamePage .right h3 {
    margin-top: 15px;
    text-align: center;
  }

  .gamePage .joystic_container {
    margin-left: 24px;
  }
  .gamePage .left .timer svg {
    margin: -4px 0;
  }
  .gamePage .sensor_joy {
    width: 157px;
  }
  .gamePage .sensor_joy .outer-circle img {
    width: 79px;
  }

  .gamePage .sensor_joy .outer-circle {
    position: absolute;
    left: 23px;
    top: 21px;
    width: 109px;
    height: 109px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: none;
  }
}

@media (orientation: landscape) and (min-width: 1023px) {

  :root {
    --card-padding-h: 22px;
  }

  .gamePage .body {
    gap: 20px;
  }

  .gamePage .left, .gamePage .right {
    width: auto;
    margin: 0;
    height: 100%;
  }

  .gamePage .body .left .card__beforeStart--started .card__beforeStart__text,
  .gamePage .card__changeMode .card__changeMode__text,
  .gamePage .right .about p,  .gamePage .right .about h3{
    font-size: 28px;
    line-height: 31.5px;
  }

  .gamePage .left .card h2 {
    font-family: Moscow Sans;
    font-size: 48px;
    font-weight: 800;
    line-height: 56.5px;
  }

  .gamePage .card__beforeStart__text {
    font-weight: 800;
  }

  .gamePage .carIcon {
    width: 54px;
    top: 11px;
    margin: 0 15px;
  }

  .gamePage .left .cursor {
    height: 50px;
    top: 16px;
    margin: 0px 10px;
  }
  
  .gamePage .gameHint__top {
    flex-direction: column;
  }
  .gamePage .gameHint__top .card {
    width: 100%;
  }
  .gamePage .card__changeMode {
    margin-left: 0;
    margin-top: 7px;
  }

  .gamePage .card__gameControls {
    padding: 70px var(--card-padding-h) !important;
  }

  .gamePage .sensor_joy {
    width: 244px;
  }

  .gamePage .sensor_joy .outer-circle {
    position: absolute;
    left: 33px;
    top: 33px;
    width: 172px;
    height: 172px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: none;
  }

  .gamePage .card__hint img:nth-child(1) {
    width: 67px;
    position: relative;
    margin-right: 25px;
    top: 3px;
  }
  .gamePage .card__hint {
    color: #313234;
    font-size: 38px;
    line-height: 18px; /* 47.368% */
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 53px var(--card-padding-h);
  }

  .gamePage .left .timer {
    color: #313234;
    font-size: 48px;
    font-weight: 800;
    line-height: 18px; /* 37.5% */
  }

  .gamePage .left .timer svg {
    margin: 0;
  }

  /* .gamePage .card__changeMode .card__changeMode__text, .gamePage .right .about h3 {
    font-size: 38px;
    line-height: 41.5px;
  } */
  .gamePage .card__changeMode .card__changeMode__text, .gamePage .right .about {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .gamePage .card__description {
    padding: 137px 29px;
  }
}

@media (orientation: landscape) and (min-width: 958px) and (min-height: 598px) {
  .gamePage .body .left div.card__beforeStart__text {
    font-size: 19px !important;
    line-height: 9px !important; /* 47.368% */
  }

  .gamePage .card__hint {
    padding: 26px var(--card-padding-h);
  }

  .gamePage .sensor_joy {
    width: 182px;
    height: 182px;
  }

  .gamePage .sensor_joy .outer-circle {
    position: absolute;
    left: 24.5px;
    top: 24px;
    width: 129px;
    height: 129px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: none;
  }

  .gamePage .sensor_joy .outer-circle img {
    width: 90.5px;
    height: 90.5px;
  }

  .gamePage .card__changeMode {
    padding-left: var(--card-padding-h);
    padding-right: var(--card-padding-h);
  }

  .gamePage .controls {
    justify-content: space-between;
  }
  .gamePage .controls p, .gamePage .controls .about h3 {
    font-size: 19px !important;
    font-weight: 400 !important;
    line-height: 20.75px !important; /* 109.211% */
  }

  .gamePage .card__beforeStart {
    min-height: 68px;
  }
  .gamePage .left .timer svg {
    width: 30px;
    height: 30px;
    margin-right: 9px;
  }

  .gamePage .controls .about img {
    width: auto;
  }

  .gamePage .card__gameControls.card--red .joystic_container {
    width: 178px !important;
    height: 178px !important;
  }
  .gamePage .card__gameControls.card--red .joystic_inner {
    width: 128.5px !important;
    height: 128.5px !important;
    border-radius: 50% !important;
    position: absolute !important;
    left: 25px !important;
    top: 25px !important;
    overflow: hidden !important;
  }
  .gamePage .card__description {
    padding: 82.5px var(--card-padding-h);
  }
  .gamePage .body {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .gamePage .body .left, .gamePage .body .right {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .gamePage .left .card.card__description {
    height: 434.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .gamePage .card__gameControls {
    height: 317px;
  }

  .gamePage .card__hint {
    font-size: 19px;
    line-height: 9px;
  }
  .gamePage .body .left .card__beforeStart--started .card__beforeStart__text, .gamePage .card__changeMode .card__changeMode__text, .gamePage .right .about p, .gamePage .right .about h3 {
    font-size: 28px;
    line-height: 31.5px;
  }
}
@media (orientation: portrait) and (min-width: 958px) and (min-height: 1920px) {
  .gamePage .body .left .card__beforeStart--started .card__beforeStart__text {
    font-size: 32px;
    line-height: 36px;
  }

  .gamePage .left .card h2, .gamePage .card__hint, .gamePage .card__changeMode .card__changeMode__text, .gamePage .right p {
    font-size: 32px;
    line-height: 36px;
  }

  .gamePage .card__description {
    padding: 42px 29px;
  }
}