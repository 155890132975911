.sessonEndPage {
  padding: 0 24px;
}

.sessonEndPage .header {
  color: var(--neutral-secondary, #515358);
  font-family: 'Moscow Sans';
font-weight: 800;
  font-size: 16px;
  font-style: normal;
  line-height: 18px;
  /* 112.5% */
  margin-top: 23px;
}

.sessonEndPage .card {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding: 0;
  overflow: hidden;
}

.sessonEndPage .left,
.sessonEndPage .right {
  width: auto;
}

.sessonEndPage .left {
  margin-left: 88px;
}

.sessonEndPage .card h3 {
  color: var(--neutral-tertiary, #72757B);
  font-family: 'Moscow Sans';
  font-weight: 800;
  font-size: 16px;
  font-style: normal;
  line-height: 18px;
  margin-top: 40px;
  margin-bottom: 0;
}

.sessonEndPage .card h2 {
  color: var(--neutral-main, #313234);
  font-family: 'Moscow Sans';
  font-weight: 800;
  font-size: 32px;
  font-style: normal;
  line-height: 40px;
  margin-top: 8px;
  margin-bottom: 0;
}

.sessonEndPage .card p {
  color: var(--neutral-secondary, #515358);
  
  font-size: 16px;
  font-style: normal;
  
  line-height: 18px;
  margin-top: 32px;
  margin-bottom: 0;
}

.sessonEndPage .card img {
  margin-bottom: 24px;
  margin-right: 27px;
}

@media (orientation: portrait) {
  .sessonEndPage {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 64px 24px;
  }
  .sessonEndPage .card {
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 63px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
  }

  .sessonEndPage .left h1 {
    color: var(--neutral-main, #313234);
    font-size: 32px;
    font-weight: 800;
    line-height: 34.5px; /* 107.813% */
  }
  .sessonEndPage .left p {
    color: var(--neutral-secondary, #515358);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-top: 32px;
  }
  .sessonEndPage .left, .sessonEndPage .right {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    height: auto;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .sessonEndPage .right{
    text-align: center;
  }
  .sessonEndPage .right img{
    width: 49%;
    margin: 0;
  }
}

@media (orientation: landscape) {
  .sessonEndPage {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 80px 24px 50px 24px;
  }
  .sessonEndPage .card {
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 35px 25px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .sessonEndPage .left, .sessonEndPage .right {
    height: 100%;
    width: auto;
    margin: 0;
  }
  .sessonEndPage .left {
    max-width: 40%;
  }
  .sessonEndPage .right img{
    height: 100%;
    margin: 0;
  }
}

@media (orientation: portrait) and (min-width: 600px) {
  .sessonEndPage .left h1 {
    font-size: 72px;
    line-height: 78px; /* 107.813% */
  }
  .sessonEndPage .left p {
    font-size: 42px;
    line-height: 46px; /* 150% */
  }
}
@media (orientation: landscape) and (min-width: 598px) {
  .sessonEndPage .left h1 {
    font-size: 72px;
    line-height: 78px; /* 107.813% */
  }
  .sessonEndPage .left p {
    font-size: 42px;
    line-height: 46px; /* 150% */
  }
  .sessonEndPage .left, .sessonEndPage .right {
    height: 50%;
  }
}