

@font-face{
  font-family: "Moscow Sans";
  src: url("assets/fonts/MoscowSans/1f110cb098263ea5a2e170384df65753.eot");
  src: url("assets/fonts/MoscowSans/1f110cb098263ea5a2e170384df65753.eot?#iefix")format("embedded-opentype"),
      url("assets/fonts/MoscowSans/1f110cb098263ea5a2e170384df65753.woff")format("woff"),
      url("assets/fonts/MoscowSans/1f110cb098263ea5a2e170384df65753.woff2")format("woff2"),
      url("assets/fonts/MoscowSans/1f110cb098263ea5a2e170384df65753.ttf")format("truetype"),
      url("assets/fonts/MoscowSans/1f110cb098263ea5a2e170384df65753.svg#Moscow Sans")format("svg");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}