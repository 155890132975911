/* Sensor.css */

.sensor_joy {
  position: relative;
  margin-left: 19px;
}

.outer-circle {
  position: absolute;
  left: 32px;
  top: 33px;
  width: 173px;
  height: 173px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
}

.inner-circle {
  position: absolute;
  border-radius: 50%; /* Создает круглую форму для внутреннего круга */
  cursor: grab; /* Курсор меняется на захват при наведении */
  user-select: none; /* Отменяет выделение текста при перемещении */
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-circle:active {
  cursor: grabbing; /* Курсор меняется на перемещение при нажатии */
}
