.avatar {
  width: 185px;
  overflow: hidden;
  position: relative;
  padding: 20px 10px 0 10px;
}
.avatar .avatar__photo{
  position: relative;
  width: 100%;
  animation: 0.4s avatarScale 0.3s linear;
  z-index: 2;
}
@keyframes avatarScale {
  0% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.2)
  }
  100% {
    transform: scale(1)
  }
}
.star {
  position: absolute;
}
.star--small {
  width: 20px;
  left: 40px;
  bottom: 50px;
  animation: star1 0.5s 1s both;
}
@keyframes star1 {
  0% {
    left: 40px;
    bottom: 50px;
    transform: scale(0);
  }
  100% {
    left: 0px;
    bottom: 90px;
    transform: scale(1);
  }
}
.star--medium {
  width: 40px;
  right: 30px;
  bottom: 50px;
  animation: star2 0.5s 1.2s both;
}
@keyframes star2 {
  0% {
    right: 30px;
    bottom: 50px;
    transform: scale(0);
  }
  100% {
    right: 0px;
    bottom: 114px;
    transform: scale(1);
  }
}

.star--large {
  width: 60px;
  right: 80px;
  bottom: 20px;
  animation: star3 0.5s 1.4s both;
}
@keyframes star3 {
  0% {
    right: 80px;
    bottom: 46px;
    transform: scale(0);
  }
  100% {
    right: 80px;
    bottom: 130px;
    transform: scale(1);
  }
}
.arc {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 46px;
  z-index: 1;
  animation: 0.2s arc linear;
}

@keyframes arc {
  0% {
    opacity: 0.4;
    transform: scale(0.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}