body {
  margin: 0;
  font-family: 'Moscow Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ECEFF2;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}