.card--red {
  background-color: #F16262;
}

.card--red .controls h3 {
  color: #FFF;
  font-weight: 400;
  text-align: left;
}
.card--red .sensorSwitcher {
  color: #FFF;
}
.card--red .about p {
  color: #FFF;
  font-size: 17px;
  font-style: normal;
  line-height: 18px; /* 105.882% */
}

.timerIcon {
  fill: #72757B;
}

.card--red .timerIcon {
  fill: #FFF;
}
.card--red .timer {
  color: #FFF;
}

@media (orientation: portrait) {
  .gamePage .card__gameControls.card--red .joystic_inner {
    width: 146px;
    height: 146px;
    border-radius: 50%;
    position: absolute;
    left: 28px;
    top: 27px;
    overflow: hidden;
  }
}
@media (orientation: landscape) {
  .gamePage .card__gameControls.card--red {
    padding: 26px 42px;
  }  
  .gamePage .card__gameControls.card--red .joystic_container {
    width: 174px;
  }  
  .gamePage .card__gameControls.card--red .joystic_container img {
    width: 100%;
  }  
  .gamePage .card__gameControls.card--red .joystic_inner {
    width: 107px;
    height: 107px;
    border-radius: 50%;
    position: absolute;
    left: 22px;
    top: 22px;
    overflow: hidden;
  }  
  /* .gamePage .card__gameControls.card--red .joystic_inner {
    width: 102px;
    height: 102px;
    border-radius: 50%;
    position: absolute;
    left: 19px;
    top: 18px;
    overflow: hidden;
  }   */
}

@media (orientation: landscape) and (min-width: 1023px) {
  .gamePage .card__gameControls.card--red .joystic_container {
    width: 244px;
  }

  .gamePage .card__gameControls.card--red .joystic_inner {
    width: 176px;
    height: 176px;
    border-radius: 50%;
    position: absolute;
    left: 34px;
    top: 34px;
    overflow: hidden;
  }
  .gamePage .card__beforeStart {
    padding: 38px var(--card-padding-h);
  }

  .gamePage .card__gameControls.card--red {
    padding: 145px var(--card-padding-h);
  }
}