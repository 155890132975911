.finalPage {
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

.finalPage .left {
  width: 60%;
}

.finalPage .right {
  width: 40%;
}

.finalPage .left h2 {
  margin-top: 0;
  color: var(--primary-main, #F16262);
  font-weight: 800;
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
}

.finalPage .info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 22px;
}

.finalPage .info .description {
  margin-left: 24px;
}
.finalPage .info .description p {
  color: var(--neutral-secondary, #515358);
  font-size: 16px;
  font-style: normal;
  line-height: 18px;
  margin-top: 24px;
  margin-bottom: 0;
}

.finalPage .route {
  width: 100%;
}

.finalPage .route h3 {
  color: #C0C5D3;
  font-weight: 800;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
  /* 114.286% */
  margin-top: 16px;
}

.finalPage .route__path {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 22px;
}

.finalPage .route__plate {
  background-color: #F4F6F9;
  border: 1px solid #BFC4CF;
  color: var(--neutral-tertiary, #72757B);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  /* 133.333% */
  padding: 12px 47px;
  border-radius: 20px;
  width: 45%;
}

.finalPage .route__arrow {
  margin: 0 8px;
}

.finalPage .right .card {
  display: flex;
  flex-direction: row;
  padding: 40px 24px;
}

.finalPage .right .card p{
  color: #515358;
  font-weight: 800;
  font-size: 16px;
  font-style: normal;
  line-height: 21px; /* 131.25% */
}

@media (orientation: portrait) {
  .finalPage {
    flex-direction: column;
    padding: 61px 24px;
  }

  .finalPage .left, .finalPage .right {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
  }
  .finalPage .left .card {
    align-items: center;
    padding: 56px 24px;
  }
  .finalPage .left .info {
    width: 100%;
  }
  .finalPage .left .description {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .finalPage .left .description p {
    margin-top: 18px;
  }
  .finalPage .right {
    margin-top: 8px;
  }
  .finalPage .route__path {
    flex-direction: column;
    margin-bottom: 0;
  }
  .finalPage .route__path img {
    display: none;
  }
  .finalPage .route__plate {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-top: 8px;
    font-size: 16px;
  }
  .finalPage .route__path h3 {
    margin-top: 0;
  }
  .finalPage .info {
    flex-direction: column;
    align-items: center;
  }
  .finalPage .info h2 {
    margin-top: 0;
  }
  .finalPage .info p {
    margin-top: 18px;
    margin-bottom: 0;
  }
  .finalPage .right .card {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
  }

  .finalPage .left .card {
    padding-bottom: 56px;
  }

  .finalPage .route h3 {
    margin-bottom: 0;
    margin-top: 20px;
  }

  .finalPage .right .card--btn {
    padding: 14px 25px;
    background-color: #F16262;
    color: white;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;
  }
  .finalPage .card--btn span {
    margin-top: 0;
    color: #FFF;
    font-size: 20px;
    line-height: 32.5px; /* 162.5% */
    letter-spacing: 0.4px;
  }
  .finalPage .avatar {
    margin-bottom: -20px;
  }
}

@media (orientation: landscape) {
  .finalPage {
    display: grid;
    grid-template-columns: 80% 20%;
    gap: 10px;
    grid-template-rows: 1fr 1fr;
  }
  .finalPage .left, .finalPage .right {
    width: auto;
    margin-right: 0;
    margin-left: 0;
  }
  .finalPage .card {
    box-sizing: border-box;
  }
  .finalPage .info, .finalPage .avatar {
    overflow: visible;
  }
  .finalPage .card .route {
    margin-top: 32px;
  }
  .finalPage .left, .finalPage .right {
    min-height: 302px;
    position: relative;
  }
  .finalPage .left .card {
    padding: 22px 42px;
  }
  .finalPage .left .avatar {
    margin-bottom: -40px;
  }
  .finalPage .left .info {
    margin: 0;
  }
  .finalPage .route {
    margin-top: 16px;
  }
  .finalPage .route h3 {
    margin-top: 0;
  }
  .finalPage .route__path {
    margin-bottom: 0;
  }
  .finalPage .right .card--btn {
    padding: 26px 24px;
    background-color: #F16262;
    color: #FFF;
    position: absolute; 
    left:0;
    right:0;
    top:0;
    bottom:0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}


.finalPage .right .card:nth-child(2) {
  background-color: #F16262;
  color: #FFF;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  color: #FFF;
  font-size: 19px;
  font-style: normal;
  line-height: 21px; /* 110.526% */
  margin-top: 14px;
}

@media (orientation: landscape) and (min-width: 1023px) {
  .finalPage .left h2 {
    font-size: 48px;
    font-weight: 800;
    line-height: 40px; /* 83.333% */
  }
  .info .description p {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    width: 70%;
  }

  .finalPage .card__user {
    padding: 145px 60px;
  }

  .finalPage .card__user .route h3 {
    font-size: 20px;
    line-height: 24px;
  }

  .finalPage .route__plate {
    font-size: 24px;
    line-height: 32px;
  }

  .finalPage .right .card:nth-child(1) p, .finalPage .right .card:nth-child(2) p {
    font-size: 32px;
    line-height: 40px;
  }

  .finalPage .right .card:nth-child(1) .time, .finalPage .right .card:nth-child(2) .time {
    font-size: 30px;
    line-height: 32px;
  }

  .finalPage .right .card:nth-child(3) {
    font-size: 40px;
    line-height: 48px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 60px;
    padding-bottom: 60px;
    height: auto;
  }
  .finalPage .right .card img {
    display: block;
    padding: 0;
    margin-bottom: 60px;
  }
  .finalPage .info .description p {
    font-size: 24px;
    line-height: 32px;
  }
}


@media (orientation: portrait) and (min-width: 958px) and (min-height: 1920px) {
  .finalPage {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .finalPage .left, .finalPage .right {
    height: auto;
  }

  .finalPage .info, .finalPage .description {
    width: 100%;
  }
  .finalPage .avatar {
    width: 460px;
  }

  .finalPage .left h2 {
    font-size: 46px;
    line-height: 48px;
  }

  .finalPage .left .description {
    margin-top: 64px;
  }

  .finalPage .info .description p, .finalPage .route h3, .finalPage .route__plate, .finalPage .right .card:nth-child(1) p, .finalPage .right .card:nth-child(2) p, .finalPage .right .card:nth-child(1) .time, .finalPage .right .card:nth-child(2) .time, .finalPage .right .card:nth-child(3), .finalPage .card--btn span {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 0;
  }

  .finalPage .right {
    margin-top: 50px;
  }

  .finalPage .route h3 {
    margin-top: 46px;
  }

  .finalPage .right .card:nth-child(3) {
    justify-content: space-between;
  }

  .finalPage .card {
    box-sizing: border-box;
  }

  .finalPage .right {
    margin-top: 50px !important;
  }

  .finalPage .btn {
    padding: 54px 25px;
  }
}

@media (orientation: landscape) and (min-width: 958px) and (min-height: 598px) {

  .finalPage {
    width: 100%;
    height: 100vh;
    padding: 12px;
    align-items: center;
    box-sizing: border-box;
    column-gap: 7.5px;
    display: flex;
    flex-direction: row;
    grid-template-columns: 80% 20%;
    grid-template-rows: 1fr 1fr;
  }

  .finalPage .left, .finalPage .right {
    height: auto;
    width: auto;
  }
  .finalPage .right .card--btn {
    position: relative;
  }
  /* .finalPage .left {
    width: 80%;
  }
  .finalPage .right {
    width: 20%;
  } */

  .finalPage .left {
    width: 100%;
  }
  .finalPage .left .card {
    padding: 142px 30px 32px 30px;
    height: 483.5px;
    flex-direction: column;
    justify-content: space-between;
  }
  .finalPage .right .card--btn {
    height: 483.5px;
    width: 162px;
    padding: 20px 14px 32px 14px;
    color: var(--color, #FFF);
    font-size: 20px;
    font-weight: 400;
    line-height: 20px; /* 120% */
    letter-spacing: 0.8px;
  }
  .finalPage .right .card img {
    margin: 0;
    width: 20px;
    width: 20px;
  }

  .finalPage .avatar {
    width: 390px;
    padding: 0;
    margin: 0;
  }

  .finalPage .left .description h2 {
    font-size: 24px !important;
    line-height: 10px !important;
  }

  .finalPage .route__plate {
    font-size: 12px;
    line-height: 32px;
  }

  .finalPage .avatar {
    width: 195px;
  }

  /* .finalPage .card__user {
    padding: 284px 60px 64px 60px;
    height: 814px;
    flex-direction: column;
    justify-content: space-between;
  }

  .finalPage .right .card:nth-child(3) {
    height: 450px;
  } */
}