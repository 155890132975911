.stationSessionEndPage {
  width: 100%;
  height: 100vh;
  padding: 85px 75px;
  box-sizing: border-box;
}

.stationSessionEndPage .card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.stationSessionEndPage .left, .stationSessionEndPage .right {
  width: auto;
  height: auto;
  margin-right: 0;
}

.stationSessionEndPage .right {
  margin-left: 120px;    
}
.stationSessionEndPage .card .left {
  min-width: 604px;
}
.stationSessionEndPage .left h1 {
  font-size: 48px;
  font-weight: 800;
  line-height: 56px; /* 116.667% */
}
.stationSessionEndPage .left p {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
  margin-top: 56px;
}

.stationSessionEndPage .left .btn {
  margin-top: 56px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 26px 24px;
  box-sizing: border-box;
}

.stationSessionEndPage .left .btn span {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px; /* 120% */
}

@media (orientation: landscape) and (min-width: 598px) {
  .stationSessionEndPage {
    width: 100%;
    height: 100vh;
    padding: 85px 75px;
    box-sizing: border-box;
  }

  .stationSessionEndPage .card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .stationSessionEndPage .left, .stationSessionEndPage .right {
    width: auto;
    height: auto;
    margin-right: 0;
  }

  .stationSessionEndPage .right {
    margin-left: 120px;    
  }
  .stationSessionEndPage .card .left {
    min-width: 604px;
  }
  .stationSessionEndPage .left h1 {
    font-size: 48px;
    font-weight: 800;
    line-height: 56px; /* 116.667% */
  }
  .stationSessionEndPage .left p {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    margin-top: 56px;
  }

  .stationSessionEndPage .left .btn {
    margin-top: 56px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 26px 24px;
    box-sizing: border-box;
  }

  .stationSessionEndPage .left .btn span {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px; /* 120% */
  }
}

@media (orientation: portrait) and (min-width: 600px) {
  .stationSessionEndPage .card {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .stationSessionEndPage .card .left {
    text-align: center;
  }
  .stationSessionEndPage .card .left p {
    text-align: center;
  }

  .stationSessionEndPage .left, .stationSessionEndPage .right {
    margin: 0;
  }
}