.joystic {
  width: 170px;
}

.joystic_container {
  position: relative;
}
.joystic_inner {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  position: absolute;
  left: 31px;
  top: 30px;
  overflow: hidden;
}

.joystic_container {
  margin-left: 28px;
}

.innerJoy {
  transition: transform 0.1s ease-in-out;
}