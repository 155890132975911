.notFoundPage {
  padding: 0 24px;
}

.notFoundPage .header {
  color: var(--neutral-secondary, #515358);
  font-family: 'Moscow Sans';
font-weight: 800;
  font-size: 16px;
  font-style: normal;
  line-height: 18px;
  /* 112.5% */
  margin-top: 23px;
}

.notFoundPage .card {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding: 0;
}

.notFoundPage .left,
.notFoundPage .right {
  width: auto;
}

.notFoundPage .left {
  margin-left: 88px;
}

.notFoundPage .card h3 {
  color: var(--neutral-tertiary, #72757B);
  font-family: 'Moscow Sans';
font-weight: 800;
  font-size: 16px;
  font-style: normal;
  line-height: 18px;
  margin-top: 40px;
  margin-bottom: 0;
}

.notFoundPage .card h2 {
  color: var(--neutral-main, #313234);
  font-family: 'Moscow Sans';
font-weight: 800;
  font-size: 32px;
  font-style: normal;
  line-height: 40px;
  margin-top: 8px;
  margin-bottom: 0;
}

.notFoundPage .card p {
  color: var(--neutral-secondary, #515358);
  
  font-size: 16px;
  font-style: normal;
  
  line-height: 18px;
  margin-top: 32px;
  margin-bottom: 0;
}

.notFoundPage .card img {
  margin-bottom: 24px;
  margin-right: 27px;
}

@media (orientation: portrait) {
  .notFoundPage .card {
    flex-direction: column;
    height: 90vh;
    padding: 0 24px;
  }
  .notFoundPage .left, .notFoundPage .right {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .notFoundPage .right img{
    width: 100%;
    margin: 0;
  }
}