.startPage, .startPage .header,  .startPage .header img {
  width: 100%;
}

.card p, .card h2, .card h3 {
  margin: 0;
  padding: 0;
  text-align: left;
}

.card {
  align-items: flex-start;
}

h2 {
  font-family: 'Moscow Sans';
font-weight: 800;
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
}

h2 span {
  color: #F16262
}

h3 {
  font-family: 'Moscow Sans';
font-weight: 800;
  font-size: 16px;
  font-style: normal;
  line-height: 18px;
}

p {
  font-size: 16px;
  font-style: normal;
  line-height: 18px; 
}

.body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  margin-top: 14px;
  background-color: #ECEFF2;
}

.left, .right {
  width: 50%;
  height: 100%;
}
.left {
  margin-right: 8px;
}
.right {
  margin-left: 8px;
}

.left .card {
  height: 100%;
  justify-content: flex-start;
  padding-bottom: 58px;
}

.title {
  opacity: 0.3;
  margin-left: 24px;
  margin-top: 12px;
}

.container_screen_container .left h2 {
  margin-top: 24px;
}
.container_screen_container .left h3 {
  margin: 16px 0;
}
.container_screen_container .right h3 {
  margin-top: 18px;
}
.container_screen_container .right p {
  display: flex;
  margin-top: 16px;
}
.container_screen_container .right p:last-child {
  margin-bottom: 24px;
}

.container_screen_container .btn {
  font-family: 'Moscow Sans';
font-weight: 800;
  font-size: 24px;
  font-style: normal;
  line-height: 32px; /* 133.333% */
  margin-top: 8px;
  width: 100%;
}
.container_screen_container .btn span {
  margin: 5px 0
}

.startPage .body {
  margin-top: -60px;
  background: transparent;
}

@media (orientation: portrait) {
  .startPage .body {
    flex-direction: column;
    margin-top: -125px;
  }

  .startPage .left, .startPage .right {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .container_screen_container .btn {
    margin-bottom: 40px;
  }
  .startPage__points {
    display: flex;
    align-items: flex-start;
    margin-top: 16px;
  }
  
  .startPage__points img {
    margin-right: 16px;
  }

  .container_screen_container .startPage__title {
    margin: 16px 0;
  }
  .container_screen_container h3.startPage__subtitle {
    margin-top: 24px;
    margin-bottom: 0;
  }

  .left .card {
    padding-bottom: 41px;
  }
  .container_screen_container .btn {
    margin-top: 10px;
  }

  .startPage .header {
    height: 325px;
    background: url('../../assets/images/start_page_header_vertical.png');
    background-position: bottom;
  }
}

@media (orientation: landscape) {
  .startPage .body {
    margin-top: -85px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    grid-template-rows: 1fr 1fr;
  }

  .startPage .header {
    height: 203px;
    background: url('../../assets/images/start_page_header_horizontal.png');
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .startPage .left, .startPage .right {
    width: 100%;
  }

  .startPage .left .card {
    padding: 40px 24px;
    box-sizing: border-box;
  }
  .startPage .left .card h2 {
    margin-top: 0;
  }
  .startPage .left .card h3.startPage__title {
    margin-top: 24px;
    margin-bottom: 8px;
  }
  
  .startPage .right p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .startPage .right .card {
    padding-top: 18px;
    padding-bottom: 24px;
  }
  .startPage .right .card h3 {
    margin-top: 0;
  }
  .startPage .right p:last-child {
    margin-top: 0;
    margin-bottom: 0;
  }
  .startPage .list__item {
    display: flex;
    align-items: flex-start;
  }
  .startPage .list__item img {
    margin-right: 16px;
  }

  .startPage .list__item {
    margin-top: 16px;
  }
  .startPage .list__item:last-child {
    margin-top: 22px;
  }
  .startPage .btn {
    padding: 12px 48px;    
  }
}

@media (orientation: landscape) and (min-width: 1023px) {
  .startPage .body {
    margin-top: 42px;
    grid-template-columns: 82% 18%;
    gap: 20px;
  }
  .startPage .bigImage {
    border-radius: 200px;
    width: 100%;
    background: #F16262;
  }

  .startPage .left, .startPage .right {
    margin: 0;
  }

  .startPage .btn {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .startPage .instructions {
    display: flex;
  }

  .startPage .left .card h2 {
    width: 70%;
    font-size: 48px;
    font-weight: 800;
    line-height: 56px; /* 116.667% */
    margin-top: 50px;
  }

  .startPage .left .card h3 {
    font-size: 24px;
    line-height: 32px;
    margin-top: 0;
  }

  .startPage .left .card .instructions h3 {
    margin-bottom: 24px;
  }

  .startPage .left .instructions {
    width: 100%;
    margin-top: 24px;
  }
  .startPage .left .instructions__description {
    width: 55%;
    margin-right: 50px;
  }
  .startPage .left .instructions__points {
    width: 45%;
  }
  .startPage .left .card p, .startPage .list__item {
    font-size: 25px;
    line-height: 27.5px;
  }
}

@media (orientation: landscape) and (min-width: 958px) and (min-height: 598px) {
  .startPage {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .startPage .left .card h3.startPage__title {
    margin-top: 0;
  }
  .startPage .body {
    display: flex;
    width: 100%;
    height: 540px;
    align-items: center;
    box-sizing: border-box;
    margin: 0;
  }

  .startPage .imageContainer {
    width: 100%;
    height: 126px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 100px;
  }

  .startPage .left .card h2 {
    font-size: 24px;
    line-height: 32px;
    width: 50%;
  }

  .startPage .left .card p, .startPage .list__item {
    font-size: 18px;
    line-height: 20px;
  }

  .startPage .left .instructions__description p {
    width: 71%;
  }

  .startPage .left .card p, .startPage .list__item {
    align-items: center;
  }

  .startPage .left .card h2, .startPage .left .instructions {
    margin-top: 24px;
  }

  .startPage .bigImage {
    border-radius: 0;
    width: 100%;
    margin-top: -10px;
  }

  .startPage .body .left, .startPage .body .right {
    height: 100%;
  }
  .startPage .body .left {
    width: 85%;
  }
  .startPage .body .left .card {
    padding: 72.5px 24.5px;
  }
  .startPage .body .right {
    width: 15%;
  }
  .startPage .body .right .btn {
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }

  .startPage .btn {
    font-size: 20px;
    font-weight: 400;
    line-height: 44px; /* 120% */
    letter-spacing: 0.8px;
    padding: 20px 14px 32px 14px;
    margin: 0;
  }
  .startPage .btn svg {
    width: 56px;
    height: 22.5px;
  }
}
@media (orientation: portrait) and (min-width: 958px) and (min-height: 1920px) {
  .startPage .body {
    margin-top: 0;
    padding: 32px 12px;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
  }

  .startPage .card {
    padding: 16px 14px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .startPage .bigImage {
    border-radius: 100px;
    width: 100%;
    background: #F16262;
  }

  .startPage .left h2 {
    font-size: 28px;
    line-height: 14px;
  }

  .startPage .left h3 {
    font-size: 21px;
    line-height: 23px;
    margin-top: 32px;
  }

  .startPage .card p {
    font-size: 16px;
    line-height: 18px;
    max-width: 90%;
  }

  .startPage .instructions__points h3{
    width: 100%;
  }
  .startPage .instructions__points .list__item{
    width: 50%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 16px;
  }

  .startPage .instructions__points .list__item span {
    font-size: 14px;
    line-height: 16px;
    display: block;
    margin-left: 12px;
  }

  .startPage .card .side {
    width: 100%;
  }  

  .startPage .btn {
    font-size: 20px;
    font-weight: 400;
    line-height: 44px; /* 120% */
    letter-spacing: 0.8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
  }
}