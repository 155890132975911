.foundedPage {
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

.foundedPage .left {
  width: 80%;
}

.foundedPage .right {
  width: 20%;
}

.foundedPage .left h2 {
  margin-top: 0;
  color: var(--primary-main, #F16262);
  font-family: 'Moscow Sans';
font-weight: 800;
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
  /* 120% */
}

.foundedPage .info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 22px;
}

.info .description p {
  color: var(--neutral-secondary, #515358);
  font-size: 16px;
  font-style: normal;
  
  line-height: 18px;
  /* 112.5% */
  margin-top: 24px;
}

.foundedPage .route {
  width: 100%;
}

.foundedPage .route h3 {
  color: #C0C5D3;
  font-family: 'Moscow Sans';
font-weight: 800;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
  /* 114.286% */
  margin-top: 16px;
}

.foundedPage .route__path {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 22px;
}

.foundedPage .route__plate {
  background-color: #F4F6F9;
  border: 1px solid #BFC4CF;
  color: var(--neutral-tertiary, #72757B);
  text-align: center;
  
  font-size: 12px;
  font-style: normal;
  
  line-height: 16px;
  /* 133.333% */
  padding: 12px 47px;
  border-radius: 20px;
  width: 45%;
}

.foundedPage .route__arrow {
  margin: 0 8px;
}

.foundedPage .right .card {
  padding: 0 14px;
}

.foundedPage .right .card:nth-child(1) p,
.foundedPage .right .card:nth-child(2) p {
  color: #000;
  
  font-size: 14px;
  font-style: normal;
  
  line-height: 16px;
  /* 114.286% */
}

.foundedPage .right .card:nth-child(1) .time,
.foundedPage .right .card:nth-child(2) .time {
  font-family: 'Moscow Sans';
font-weight: 800;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
  /* 133.333% */
  margin-bottom: 19px;
}

.foundedPage .right .card:nth-child(1) .time {
  color: #FF8E25;
}
.foundedPage .right .card:nth-child(2) .time {
  color: #6EBE2F;
}
.foundedPage .right .card:nth-child(2) {
  border: 3px solid #6EBE2F;
}
.foundedPage .right .card:nth-child(3) {
  background-color: #F16262;
  color: #FFF;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  color: #FFF;
  
  font-size: 19px;
  font-style: normal;
  
  line-height: 21px; /* 110.526% */
  margin-top: 8px;
  height: 100px;
}

.foundedPage .flag {
  margin-left: 5px;
}

.foundedPage .card {
  margin: 0;
  padding: 0;
}

@media (orientation: portrait) {
  .foundedPage {
    padding: 61px 24px;
    flex-direction: column;
  }
  .foundedPage .left, .foundedPage .right {
    width: 100%;
    margin: 0;
  }

  .foundedPage .card__user {
    padding: 24px;
  }

  .foundedPage .card__user .info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .foundedPage .card__user .avatar {
    padding: 0;
    width: 160px;
    margin-top: -32px;
    margin-bottom: -32px;
  }

  .foundedPage .left h2 {
    margin-top: 15px;
  }

  .foundedPage .info .description p {
    margin-top: 18px;
  }

  .foundedPage .route h3 {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .foundedPage .route__path {
    flex-direction: column;
    margin-bottom: 0;
  }
  .foundedPage .route__path img {
    display: none;
  }

  .foundedPage .route__plate {
    width: 100%;
    padding: 12px 0;
    text-align: center;
    color: #313234;
    font-size: 16px;
    line-height: 16px; /* 100% */
  }

  .foundedPage .route__plate:last-child {
    margin-top: 8px;
  }

  .foundedPage .right .card {
    margin-top: 8px;
  }

  .foundedPage .right .card--route {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 28px 24px;
  }
  .foundedPage .right .card--route p, .foundedPage .right .card--route div {
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media (orientation: landscape) {
  .foundedPage {
    padding: 24px;
    display: grid;
    grid-template-columns: 80% 20%;
    gap: 10px;
    grid-template-rows: 1fr 1fr;
  }

  .foundedPage .left, .foundedPage .right {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }

  .foundedPage .card__user {
    padding: 22px 42px;
    box-sizing: border-box;
  }

  .foundedPage .route {
    margin-top: 32px;
  }

  .foundedPage .info {
    margin: 0;
    align-items: center;
  }

  .foundedPage .avatar {
    width: 390px;
    padding: 0;
    margin: -36px -25px;
  }

  .foundedPage .card__user .description {
    margin-left: 24px;
  }
  .foundedPage .card__user .route h3 {
    margin-bottom: 8px;
  }

  .foundedPage .route__path {
    margin-bottom: 0;
  }

  .foundedPage .right .card:nth-child(1) .time, .foundedPage .right .card:nth-child(2) .time {
    margin-bottom: 0;
    margin-top: 1px;
  }

  .foundedPage .right .card:nth-child(1) p, .foundedPage .right .card:nth-child(2) p {
    margin-top: 0;
  }

  .foundedPage .right .card--route {
    padding: 19px 24px;
  }

  .foundedPage .right .card {
    margin-top: 8px;
  }
  .foundedPage .right .card:nth-child(1) {
    margin-top: 0;
  }
  .foundedPage .card--btn img {
    display: none;
    padding: 24px;
  }
}

@media (orientation: landscape) and (min-width: 1023px) {
  .foundedPage .left h2 {
    font-size: 48px;
    font-weight: 800;
    line-height: 40px; /* 83.333% */
  }
  .info .description p {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    width: 70%;
  }

  .foundedPage .card__user {
    padding: 145px 60px;
  }

  .foundedPage .card__user .route h3 {
    font-size: 20px;
    line-height: 24px;
  }

  .foundedPage .route__plate {
    font-size: 24px;
    line-height: 32px;
  }

  .foundedPage .right .card:nth-child(1) p, .foundedPage .right .card:nth-child(2) p {
    font-size: 32px;
    line-height: 40px;
  }

  .foundedPage .right .card:nth-child(1) .time, .foundedPage .right .card:nth-child(2) .time {
    font-size: 30px;
    line-height: 32px;
  }

  .foundedPage .right .card:nth-child(3) {
    font-size: 40px;
    line-height: 48px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 60px;
    padding-bottom: 60px;
    height: auto;
  }
  .foundedPage .right .card img {
    display: block;
    padding: 0;
    margin-bottom: 60px;
  }
}


@media (orientation: portrait) and (min-width: 958px) and (min-height: 1920px) {
  .foundedPage {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .foundedPage .left, .foundedPage .right {
    height: auto;
  }

  .foundedPage .card__user .info, .foundedPage .card__user .description {
    width: 100%;
  }
  .foundedPage .card__user .avatar {
    width: 460px;
  }

  .foundedPage .left h2 {
    font-size: 46px;
    line-height: 48px;
  }

  .foundedPage .info .description p, .foundedPage .route h3, .foundedPage .route__plate, .foundedPage .right .card:nth-child(1) p, .foundedPage .right .card:nth-child(2) p, .foundedPage .right .card:nth-child(1) .time, .foundedPage .right .card:nth-child(2) .time, .foundedPage .right .card:nth-child(3) {
    font-size: 32px;
    line-height: 36px;
  }

  .foundedPage .right {
    margin-top: 50px;
  }

  .foundedPage .route h3 {
    margin-top: 46px;
  }

  .foundedPage .right .card:nth-child(3) {
    justify-content: space-between;
  }
}

@media (orientation: landscape) and (min-width: 958px) and (min-height: 598px) {


  .foundedPage .right .card img {
    margin: 0;
    width: 40px;
    width: 40px;
  }

  .foundedPage {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    gap: 7.5px;
  }

  .foundedPage .avatar {
    width: 195px;
    margin: 0;
    padding: 0;
  }

  .foundedPage .left, .foundedPage .right {
    height: auto;
  }

  .foundedPage .card__user {
    padding: 142px 30px 32px 30px;
    height: 483.5px;
    flex-direction: column;
    justify-content: space-between;
  }

  .foundedPage .right .card {
    margin-top: 7.5px;
  }
  .foundedPage .right .card:nth-child(1) {
    margin-top: 0;
  }

  .foundedPage .right .card:nth-child(2) {
    border: 3px solid #6EBE2F;
  }

  .foundedPage .right .card:nth-child(3) {
    height: 220px;
    padding: 20px 14px 32px 14px;
  }

  .foundedPage .left h2 {
    font-size: 24px;
    line-height: 20px;
  }

  .info .description p {
    font-size: 16px !important;
    line-height: 18px !important;
  }

  .foundedPage .right .card:nth-child(1) p, .foundedPage .right .card:nth-child(2) p {
    font-size: 16px;
    line-height: 20px;
  }

  .foundedPage .right .card:nth-child(3) {
    font-size: 20px;
    line-height: 24px;
  }

  .foundedPage .route {
    margin-top: 0;
  }
}