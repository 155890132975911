.welcomePage {
  background-color: #F16262;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.welcomePage .text {
  color: var(--black-white-white, #FFF);
  text-align: center;
  font-family: "Moscow Sans Bold";
  font-size: 20px;
  font-style: normal;
  line-height: 24px; /* 120% */
  margin-top: 40px;
}

.welcomePage .icon {
  margin-top: 40px;
}

.welcomePage .header {
  width: 100%;
}

.welcomePage .header img {
  width: 100%;
}

@media (orientation: landscape) {
  .welcomePage .icon {
    margin-top: 25px;
  }
}


/* @media (orientation: landscape) and (min-width: 480px) {
  .welcomePage .header img {
    width: 100%;
  }
} */

.loader p {
  color: #FFF;
  text-align: center;
  font-family: "Moscow Sans";
  font-weight: 800;
  font-size: 20px;
  font-style: normal;
  line-height: 24px; /* 120% */
}

.loader {
  position: absolute;
  margin-bottom: 29px;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  padding: 0 28px;
}

.loader__bar {
  border-radius: 4px;
  border: 1px solid var(--neutral-border, #BFC4CF);
  width: 100%;
  height: 8px;
  overflow: hidden;
  margin-top: 17px;
  position: relative;
}

.loader__inner {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: #FFF;
  animation: progressAnimation 2s linear;
}

.loader__background {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.4;
  background: var(--black-white-white, #FFF);
}

@keyframes progressAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}