.btn {
  display: flex;
  padding: 16px 48px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  background: #F16262;
  box-shadow: 0px 12px 20px 0px rgba(241, 98, 98, 0.30);
  color: #FFF;
  border: none;
  outline: none;
}

.btn svg {
  fill: #FFF;
}

.btn:hover {
  background-color: #FF7272;
}

.btn:active {
  background-color: #EF5858;
}
.btn:disabled {
  background-color: #E3E5E7;
  color: #72757B;
  box-shadow: none;
}
.btn:disabled span {
  opacity: 0.5;
}
.btn:disabled svg {
  fill: #515358
}